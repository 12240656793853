import React, { ComponentType, CSSProperties, ReactNode } from 'react'
import { Game, GameState, Tournament } from 'types'
import Link from 'components/router/Link'
import {
  gamePath,
  roundPath,
  tournamentSchedulePath,
  widgetTournamentSchedulePath
} from 'shared/urlHelpers'
import Image from 'components/ui/basic/Image'
import {
  formattedDateFull,
  formattedDateFullShort,
  formattedTime
} from 'shared/timeHelper'
import checkLogo from 'shared/imageHelpers'
import { getExtraStateNames } from 'data/helpers'
import LinkButton from 'components/router/LinkButton'

export const ScheduleListItem: ComponentType<{
  game: Game
  showTournament?: boolean
  showPhase?: boolean
  showRound?: boolean
  showField?: boolean
  skipDate?: boolean
}> = ({
  game,
  showTournament,
  showPhase,
  showRound,
  showField,
  skipDate = false
}) => {
  const extraStates = getExtraStateNames(game)

  return (
    <div className="view-vertical">
      <div className="schedule-list-item-additional">
        {showTournament && game.tournament && (
          <Link
            href={tournamentSchedulePath(game.tournament)}
            tooltip={game.tournament.name}
          >
            {game.tournament.name}
          </Link>
        )}

        {showPhase && game.phase && (
          <Link
            href={`${tournamentSchedulePath(game.tournament)}?phase=${
              game.phase.id
            }`}
            tooltip={game.phase.name}
          >
            {game.phase.name}
          </Link>
        )}

        {showRound && game.round && (
          <Link
            href={roundPath(game.tournament, game.round)}
            tooltip={game.round.name}
          >
            {game.round.name}
          </Link>
        )}

        {showField && game.field && game.field.club && (
          <span>{game.field.club.name}</span>
        )}

        {extraStates && <span>{extraStates}</span>}
      </div>

      <Link href={gamePath(game)} className="schedule-list-item">
        <div className="schedule-list-item-teams">
          <div className="schedule-list-item-team-wrapper mb-2">
            <span className="schedule-list-item-logo-wrapper">
              <Image
                className="w-full h-full"
                src={checkLogo(game.homeTeam.logo, 'club')}
                alt={`Logo ${game.homeTeam.name}`}
                objectFit="contain"
              />
            </span>
            <span className="schedule-list-item-team">
              {game.homeTeam.name}
            </span>
          </div>
          <div className="schedule-list-item-team-wrapper">
            <span className="schedule-list-item-logo-wrapper w-8 h-8">
              <Image
                className="w-full h-full"
                src={checkLogo(game.awayTeam.logo, 'club')}
                alt={`Logo ${game.awayTeam.name}`}
                objectFit="contain"
              />
            </span>
            <span className="schedule-list-item-team">
              {game.awayTeam.name}
            </span>
          </div>
        </div>
        {game.state === GameState.PRE && (
          <div className="schedule-list-item-starts-at">
            {game.showTime && (
              <>
                {!skipDate && (
                  <div className="schedule-list-item-date mb-2 uppercase font-semibold">
                    {formattedDateFullShort(game.startsAt)}
                  </div>
                )}
                <div className="schedule-list-item-time">
                  {formattedTime(game.startsAt)} Uhr
                </div>
              </>
            )}
          </div>
        )}
        {game.state === GameState.LIVE && (
          <div className="schedule-list-item-starts-at live">
            <div className="schedule-list-item-score">{game.homeGoals}</div>
            <div className="schedule-list-item-score">{game.awayGoals}</div>
          </div>
        )}
        {game.state === GameState.POST && (
          <div className="schedule-list-item-starts-at post">
            <div className="schedule-list-item-score">{game.homeGoals}</div>
            <div className="schedule-list-item-score">{game.awayGoals}</div>
          </div>
        )}
        {(game.state === GameState.POSTPONED ||
          game.state === GameState.CANCELED) && (
          <div className="schedule-list-item-starts-at postponed">
            <div className="schedule-list-item-score">{game.homeGoals}</div>
            <div className="schedule-list-item-score">{game.awayGoals}</div>
          </div>
        )}
      </Link>
    </div>
  )
}

const GroupByDate = ({
  index,
  games,
  game
}: {
  index: number
  games: Game[]
  game: Game
}) => {
  const currentMatchTime = game.startsAt
  const previousMatchTime = index > 0 ? games[index - 1].startsAt : 0
  if (index === 0 || previousMatchTime !== currentMatchTime) {
    const date = new Date(currentMatchTime).toISOString().split('T')[0]
    const anchorOffsetFix: CSSProperties = {
      paddingTop: '100px',
      marginTop: '-100px',
      display: 'table-caption',
      pointerEvents: 'none'
    }
    return (
      <div className="" id={date} style={anchorOffsetFix}>
        <p className="headline pt-4 border-t border-gray-300">
          {formattedDateFull(currentMatchTime)}
        </p>
      </div>
    )
  } else {
    return null
  }
}

const GameSchedule: ComponentType<{
  schedule: Game[]
  navigation?: ReactNode
  showTournament?: boolean
  tournament?: Tournament
  showRound?: boolean
  showField?: boolean
  skipDate?: boolean
}> = ({
  schedule,
  navigation,
  showTournament,
  tournament,
  showRound,
  showField
}) => {
  return (
    <div className="view-vertical">
      {navigation}
      <div className="view-vertical space-y-6">
        {schedule.length === 0 && <p>Es liegen noch keine Spieldaten vor!</p>}

        {schedule.map((game, index) => (
          <div id={game.id} key={game.id} className="space-y-6">
            <div className="view-vertical">
              <GroupByDate index={index} games={schedule} game={game} />
              <ScheduleListItem
                game={game}
                showTournament={showTournament}
                showRound={showRound}
                showField={showField}
                skipDate={true}
              />
            </div>
          </div>
        ))}

        {tournament && (
          <div className="text-center">
            <LinkButton href={widgetTournamentSchedulePath(tournament)}>
              Füge den Spielplan{' '}
              {tournament.tournamentType === 'League'
                ? 'der Liga'
                : 'des Wettbewerbes'}{' '}
              zu deiner Webseite hinzu
            </LinkButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default GameSchedule
