import { Game, Schedule } from 'types'

export const toSeasonName = (season: string): string => {
  const nextYear = parseInt(season) + 1

  return `${season}/${nextYear.toString().slice(-2)}`
}

export const verifyQueryId = (
  queryId: string | string[] | undefined
): string => {
  if (!queryId || Array.isArray(queryId)) throw new Error('ID has to be string')
  else return queryId
}

export const closesDateInShedule = (data: Schedule): Date => {
  const target = new Date(new Date().getTime() - 6 * 60 * 60 * 1000).getTime()
  const closest = data
    .map((game) => game.startsAt)
    .reduce(function (prev, curr) {
      return Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
    })

  return new Date(closest)
}

export const isSidGame = (game: Game): boolean => {
  return game.id.startsWith('sid')
}

export const getExtraStateNames = (game: Game): string | null => {
  const extraStateTitles = {
    Custom: 'Sonderwertung',
    WoHome: 'WH (Wertung gegen Heim)',
    WoAway: 'WG (Wertung gegen Gast)',
    WoBoth: 'W2 (Wertung gegen Beide)',
    NotParticipatedHome: 'NH (nicht Antreten Heim)',
    NotParticipatedAway: 'NG (nicht Antreten Gast)',
    NotParticipatedBoth: 'N2 (nicht Antreten beide)',
    Revaluation: 'U (Umwertung durch Spielleiter)',
    ProtestHome: 'EH (Einspruch Heim)',
    ProtestAway: 'EG (Einspruch Gast)',
    ReleasedHomeTeam: 'ZH (Zurückgezogen Heim)',
    ReleasedAwayTeam: 'ZG (Zurückgezogen Gast)'
  }

  const extraStates: string[] = []
  if (!game.extraStates || game.extraStates.length === 0) {
    return null
  }

  game.extraStates.forEach((state) => {
    const value = extraStateTitles[state]
    if (value) {
      extraStates.push(value)
    }
  })

  return extraStates.join(', ')
}

export const toLocalISODateString = (date: Date): string => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}
