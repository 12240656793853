'use client'

import LinkButton from 'components/router/LinkButton'
import { ScheduleListItem } from 'components/schedule/GameSchedule'
import Button from 'components/ui/basic/Button'
import Image from 'components/ui/basic/Image'
import { getExtraStateNames } from 'data/helpers'
import Link from 'next/link'
import { ComponentType, useState } from 'react'
import {
  ChevronDown,
  ChevronUp,
  Circle,
  Clock,
  FileText,
  Info,
  User,
  Users
} from 'react-feather'
import checkLogo from 'shared/imageHelpers'
import {
  formattedDateFullShort,
  formattedDateTimeFull,
  formattedTime
} from 'shared/timeHelper'
import {
  fieldPath,
  // gameCalenderPath,
  gamePath,
  gamePresstextPath,
  scheduleSearchPath,
  tournamentSchedulePath
} from 'shared/urlHelpers'
import { Game, GameState, Schedule } from 'types'
// import PushIcon from 'public/img/ci-icons/pushnachrichten.svg';
import LivePlayIcon from 'public/img/ci-icons/live-play.svg'
// import KalenderIcon from 'public/img/ci-icons/kalender.svg'
import OrtungHalleIcon from 'public/img/ci-icons/ortung-halle.svg'
import CalendarImport from 'components/layout/CalendarImport'

export const ScheduleSearchTable: ComponentType<{
  schedule: Schedule
  style?: 'list' | 'card'
}> = ({ schedule, style = 'card' }) => {
  if (schedule.length === 0) {
    return null
  }
  const isListStyle = style === 'list'
  return (
    <div
      className={`@container/schedule ${
        isListStyle ? 'divide-y' : 'space-y-1'
      }`}
    >
      {schedule.map((game) => (
        <div key={game.id} className="">
          <div className={isListStyle ? '-mx-4' : ''}>
            <ScheduleSearchTableRow game={game} />
            <ScheduleSearchTableRowMobile game={game} />
          </div>
        </div>
      ))}
    </div>
  )
}

// function ScheduleSearchTableHeader() {
//   return (
//     <div className="hidden md:grid grid-cols-11 py-4 whitespace-nowrap text-sm gap-4">
//       <div className="col-span-3 flex gap-4 pl-4">
//         <div className="flex-1">Datum</div>
//         <div className="flex-1">Staffel</div>
//       </div>
//       <div className="col-span-3 text-end">Heim</div>
//       <div />
//       <div className="col-span-3">Gast</div>
//       <div className="col-span-1"></div>
//     </div>
//   )
// }

const ScheduleSearchTableRowDetails: ComponentType<{
  game: Game
  className?: string
}> = ({ game, className }) => {
  return (
    <>
      <div className="grid @xl/schedule:grid-cols-2 px-4">
        <div className="py-3 @xl/schedule:pl-3">
          <div className="text-sm text-gray-500 dark:text-gray-400 flex items-baseline gap-1">
            <Clock className="w-4 h-4 relative top-0.5 inline-block" />
            Spielbeginn
          </div>
          <div className="">
            <Link href={gamePath(game)}>
              {formattedDateTimeFull(game.startsAt)}
            </Link>
          </div>

          {game.field && (
            <>
              <div className="text-sm mt-2 text-gray-500 dark:text-gray-400 flex items-baseline gap-1">
                <OrtungHalleIcon className="w-4 h-4 relative top-0.5 inline-block" />
                Halle
              </div>
              <div className="">
                {game.field && (
                  <Link href={fieldPath(game.field)}>
                    {game.field.name}, {game.field.city}{' '}
                    {/* <ChevronRight className="w-4 h-4 inline-block -mt-px" /> */}
                  </Link>
                )}
              </div>
            </>
          )}

          <div className="text-sm mt-2 text-gray-500 dark:text-gray-400 flex items-baseline gap-1">
            <Circle className="w-4 h-4 relative top-0.5 inline-block" />
            Staffel / Runde
          </div>
          <div className="">
            <Link
              href={
                tournamentSchedulePath(game.tournament) +
                `?phase=${game.phase.id}`
              }
            >
              {game.phase.name}
            </Link>{' '}
            /{' '}
            <Link
              href={
                tournamentSchedulePath(game.tournament) +
                `?phase=${game.phase.id}&round=${game.round.id}`
              }
            >
              {game.round.name}
            </Link>
          </div>

          {game.refereeInfo && (
            <>
              <div className="text-sm mt-2 text-gray-500 dark:text-gray-400 flex items-baseline gap-1">
                <User className="w-4 h-4 relative top-0.5 inline-block" />
                Schiedsrichter
              </div>
              <div className="">
                <Link href={gamePath(game)}>{game.refereeInfo}</Link>
              </div>
            </>
          )}

          {game.attendance && game.attendance > 0 && (
            <>
              <div className="text-sm mt-2 text-gray-500 dark:text-gray-400 flex items-baseline gap-1">
                <Users className="w-4 h-4 relative top-0.5 inline-block" />
                Zuschauer
              </div>
              <div className="">
                <Link href={gamePath(game)}>{game.attendance}</Link>
              </div>
            </>
          )}

          {game.extraStates && game.extraStates.length > 0 && (
            <>
              <div className="text-sm mt-2 text-gray-500 dark:text-gray-400 flex items-baseline gap-1">
                <Info className="w-4 h-4 relative top-0.5 inline-block" />
                Hinweis
              </div>
              <div className="">
                <Link href={gamePath(game)}>{getExtraStateNames(game)}</Link>
              </div>
            </>
          )}
        </div>

        <div className="divide-y divide-gray-200 mt-4 border-t border-gray-200 @xl/schedule:pl-10 @xl/schedule:border-t-transparent @xl/schedule:mt-0">
          <div>
            {/* TODO: Timo: can you please check why the overlay does only overlap the search table and not the whole screen? */}
            <CalendarImport
              option={{ id: game.id, title: 'Spiel', type: 'game' }}
              size="l"
              inlineOverlay={true}
              currentPath={scheduleSearchPath()}
            />
            {/* <LinkButton
              className="w-full block"
              buttonClassName="btn-short !pl-0 !m-0 !py-3 w-full @xl/schedule:justify-start"
              color="link"
              href={gameCalenderPath(game.id, 'apple')}
            >
              <KalenderIcon className="ci-icon" />
              <span>Kalender abonnieren</span>
            </LinkButton> */}
          </div>
          {game.livestreamUrl && (
            <LinkButton
              className="w-full block"
              buttonClassName="btn-short !pl-0 !m-0 !py-3 w-full @xl/schedule:justify-start"
              color="link"
              href={game.livestreamUrl}
              openInNewWindow={true}
              tooltip="zum Livestream"
            >
              <LivePlayIcon width={24} height={24} className="ci-icon" />
              <span>Livestream</span>
            </LinkButton>
          )}
          {game.showPresstext && (
            <div>
              <LinkButton
                className="w-full block"
                buttonClassName="btn-short !pl-0 !m-0 !py-3 w-full md:justify-start"
                color="link"
                href={gamePresstextPath(game)}
              >
                <FileText className="ci-icon" />
                <span>Spielbericht</span>
              </LinkButton>
            </div>
          )}
          {/* <div>
            <LinkButton
              className="w-full block"
              buttonClassName="btn-short !pl-0 !m-0 !py-3 w-full @xl/schedule:justify-start"
              color="link"
              href={gamePath(game)}
            >
              <Star className="ci-icon" />
              <span>Favorit hinzufügen</span>
            </LinkButton>
          </div> */}
          {/* <div>
            <LinkButton
              className="w-full block"
              buttonClassName="btn-short !pl-0 !m-0 !py-3 w-full @xl/schedule:justify-start"
              color="link"
              href={gamePath(game)}
            >
              <PushIcon className="ci-icon" />
              <span>Push Notifications</span>
            </LinkButton>
          </div> */}
        </div>
      </div>
    </>
  )
}

export const ScheduleSearchTableRow: ComponentType<{ game: Game }> = ({
  game
}) => {
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false)

  const toggleDetailsOpen = () => {
    setDetailsOpen(!detailsOpen)
  }

  return (
    <div
      className={`hidden @xl/schedule:block  bg-white rounded-md transition-all scale-100 ${
        detailsOpen ? 'ring-2 ring-primary shadow-lg relative' : 'shadow-none'
      }`}
    >
      <div className="grid grid-cols-[6rem_1fr_4rem_1fr_4.75rem] @2xl/schedule:grid-cols-[7rem_1fr_4rem_1fr_4.75rem] align-middle items-center leading-tight gap-x-4 group">
        <Link href={gamePath(game)} className="contents">
          <div className=""></div>
          <div className="col-span-3 break-all text-center text-sm text-gray-500 pt-4 pb-2">
            {game.phase.name}
          </div>
          <div className=""></div>
          <div className="pl-4 pb-4 text-sm @2xl/schedule:text-base">
            {getDateLabel(game.startsAt, game.state)}
          </div>
          <div
            className="flex gap-2 items-center pb-4 link group-has-[.link:hover]:text-green text-sm @2xl/schedule:text-base"
            title={game.homeTeam.name}
          >
            <div className="flex-1 line-clamp-2 leading-[1.2] font-bold text-right">
              {game.homeTeam.name}
            </div>

            <Image
              className="w-6 h-6 shrink-0"
              src={checkLogo(game.homeTeam.logo, 'club')}
              alt={`Logo ${game.homeTeam.name}`}
              objectFit="contain"
            />
          </div>
          <div className="flex items-center justify-center pb-4">
            <ScoreBox className="w-full" game={game} />
          </div>
          <div
            className="flex justify-start gap-2 items-center pb-4"
            title={game.awayTeam.name}
          >
            <Image
              className="w-6 h-6 shrink-0"
              src={checkLogo(game.awayTeam.logo, 'club')}
              alt={`Logo ${game.awayTeam.name}`}
              objectFit="contain"
            />
            <div className="flex-1 line-clamp-2 leading-[1.2] font-bold text-sm @2xl/schedule:text-base">
              {game.awayTeam.name}
            </div>
          </div>
        </Link>
        <div className="pb-4 pr-1">
          <div className="flex w-full justify-end items-center gap-2">
            {game.livestreamUrl && (
              <LinkButton
                className="!m-0"
                buttonClassName="!p-0.5"
                href={game.livestreamUrl}
                openInNewWindow={true}
                iconOnly
                tooltip="zum Livestream"
              >
                <LivePlayIcon />
              </LinkButton>
            )}
            <div title={detailsOpen ? 'Details schließen' : 'Details öffnen'}>
              <Button
                className="!m-0"
                onClick={toggleDetailsOpen}
                iconOnly
                color={'white'}
                tooltip={detailsOpen ? 'Details schließen' : 'Details öffnen'}
              >
                {detailsOpen ? <ChevronUp /> : <ChevronDown />}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={`pb-4 ${detailsOpen ? 'block' : 'hidden'}`}>
        <hr className="text-gray-500 pb-4 mx-4" />
        <ScheduleSearchTableRowDetails game={game} />
      </div>
    </div>
  )
}

export const ScheduleSearchTableRowMobile: ComponentType<{ game: Game }> = ({
  game
}) => {
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false)

  const toggleDetailsOpen = () => {
    setDetailsOpen(!detailsOpen)
  }

  const liveOrPastGame =
    game.state === GameState.LIVE || game.state == GameState.POST

  return (
    <div
      className={`scale-100 @xl/schedule:hidden block bg-white p-4 align-middle items-center ${
        detailsOpen ? 'ring-2 ring-primary shadow-lg relative' : 'shadow-none'
      }`}
    >
      {/* <Link className="block" href={gamePath(game)}>  // nested link causes hydrate error */}
      <div className="flex justify-between items-center">
        <div className={`font-bold ${liveOrPastGame ? 'mb-2' : 'mb-4'}`}>
          {getDateLabel(game.startsAt, game.state)}
        </div>
        <div className="flex justify-end">
          {game.livestreamUrl && (
            <LinkButton
              href={game.livestreamUrl}
              openInNewWindow={true}
              iconOnly
              color="white"
              tooltip="zum Livestream"
            >
              <LivePlayIcon />
            </LinkButton>
          )}
          {/* <Button iconOnly color="white" tooltip="Kalender abonnieren">
            <KalenderIcon />
          </Button> */}
          <Button
            onClick={toggleDetailsOpen}
            iconOnly
            color={'white'}
            tooltip={detailsOpen ? 'Details schließen' : 'Details öffnen'}
          >
            {detailsOpen ? (
              <ChevronUp />
            ) : (
              <>
                <ChevronDown />
              </>
            )}
          </Button>
        </div>
      </div>
      <ScheduleListItem showPhase={true} game={game} />
      {/* <div className="flex flex-col justify-between items-center w-full">
          <div className="flex w-full justify-between">
            <div className="space-y-px">
              <div className="flex gap-4 py-1">
                <Image
                  className="w-6 h-6"
                  src={checkLogo(game.homeTeam.logo, 'club')}
                  alt={`Logo ${game.homeTeam.name}`}
                  objectFit="contain"
                />
                <div className=" truncate " title={game.homeTeam.name}>
                  {game.homeTeam.name}
                </div>
              </div>

              <div className="flex gap-4 py-1">
                <Image
                  className="w-6 h-6"
                  src={checkLogo(game.awayTeam.logo, 'club')}
                  alt={`Logo ${game.awayTeam.name}`}
                  objectFit="contain"
                />
                <div className="truncate" title={game.awayTeam.name}>
                  {game.awayTeam.name}
                </div>
              </div>
            </div>
            {(game.state === GameState.LIVE || game.state == GameState.POST) && (
              <div className="space-y-[1px]">
                <div className="py-1 px-2 min-w-[3.5rem] text-white bg-black rounded-t-md text-center">
                  {game.homeGoals}
                </div>
                <div className=" py-1 px-2 min-w-[3.5rem] text-white bg-black rounded-b-md text-center">
                  {game.awayGoals}
                </div>
              </div>
            )}
            {game.state !== GameState.LIVE && game.state !== GameState.POST && (
              <ScoreBox className='min-w-[3.5rem]' game={game} />
            )}
          </div>
        </div> */}
      {/* </Link> // nested link causes hydrate error  */}

      <div className={detailsOpen ? 'block' : 'hidden'}>
        <hr className="text-gray-500 w-full mt-4 mb-4" />
        <ScheduleSearchTableRowDetails game={game} />
      </div>
    </div>
  )
}

const ScoreBox: ComponentType<{ game: Game; className?: string }> = ({
  game: { showTime, awayGoals, homeGoals, state, startsAt },
  className
}) => {
  switch (state) {
    case GameState.PRE:
      return (
        <div
          className={`px-2 pt-1 bg-gray-200 rounded-sm flex flex-col justify-center items-center ${
            className || ''
          }`}
        >
          {showTime && (
            <>
              <div className="">{formattedTime(startsAt)}</div>
              <div className="-mt-0.5 uppercase text-xs text-gray-500 text-center font-semibold">
                Uhr
              </div>
            </>
          )}
          {!showTime && <div className="">{'- : -'}</div>}
        </div>
      )

    case GameState.LIVE:
      return (
        <div
          className={`bg-primary text-white rounded-sm py-2 font-bold text-center ${
            className || ''
          }`}
        >
          {homeGoals} : {awayGoals}
        </div>
      )
    case GameState.POST:
      return (
        <div
          className={`bg-black text-white rounded-sm py-2 font-bold text-center ${
            className || ''
          }`}
        >
          {`${homeGoals} : ${awayGoals}`}
        </div>
      )
    case GameState.POSTPONED:
      return (
        <div
          className={`p-2 bg-gray-200 rounded-sm text-center ${
            className || ''
          }`}
        >
          ver.
        </div>
      )
    case GameState.CANCELED:
      return (
        <div
          className={`p-2 bg-gray-200 rounded-sm text-center ${
            className || ''
          }`}
        >
          abg.
        </div>
      )
  }
}

function getDateLabel(ts: number, gamestate: GameState) {
  const date = new Date(ts).toISOString().split('T')[0]
  const today = new Date().toISOString().split('T')[0]
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const tomorrowISO = tomorrow.toISOString().split('T')[0]
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const yesterdayISO = yesterday.toISOString().split('T')[0]

  if (gamestate === GameState.LIVE) {
    return 'JETZT LIVE'
  }

  if (date === today) {
    return 'HEUTE'
  } else if (date === tomorrowISO) {
    return 'MORGEN'
  } else if (date === yesterdayISO) {
    return 'GESTERN'
  }

  return formattedDateFullShort(ts)
}
